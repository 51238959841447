* {
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: Cyberspace;
  src: url("Resources/cyberspace.otf") format("opentype");
}

@font-face {
  font-family: NeutraTextBook;
  src: url("Resources/NeutraText-Book.otf") format("opentype");
}

@font-face {
  font-family: NeutraTextDemiSC;
  src: url("Resources/NeutraText-DemiSC.otf") format("opentype");
}

@font-face {
  font-family: NeutraTextBookItalic;
  src: url("Resources/NeutraText-BookItalic.otf") format("opentype");
}

@font-face {
  font-family: NeutraTextDemi;
  src: url("Resources/NeutraText-Demi.otf") format("opentype");
}

html {
  height: 100%;
  font-family: NeutraTextBook;
}

p {
  margin: 20px 0px;
  line-height: 32px;
}

body {
  background-color: #fcfcfc;
  height: 100%;
  margin: 0;
}

h1 {
  font-family: Cyberspace;
  color: #333;
  margin: 0;
}

a {
  text-decoration: none;
}

data-reactroot {
  height: 100%;
}

root {
  height: 100%;
}
